import React, { useEffect, useState } from 'react';

import { Stack, Container, Divider, Chip, List, CircularProgress, ListItem, Box, Typography } from '@mui/material';

import ListCard from '../components/ListCard';

import { Item } from '../components/ListCard';
import { useScrollRestore, useSearch, matchItem } from '../utils';

function AbstractList(props: {active: boolean, abstracts: Array<Item>}) {
  useScrollRestore(props.active);

  const [query, renderSearch] = useSearch();
  const abstracts = (query === "" ? props.abstracts : props.abstracts.reduce((acc, item) => {
    if (matchItem(query, item)) {
      return [...acc, item];
    }
    return acc;
  }, [] as Array<Item>));

  return (
    <Container maxWidth="sm">
      <List>
        <Stack gap={1}>
          {abstracts.map(e => (
            <ListItem key={`item-${e.pk}`}>
              <Box width="100%">
                <ListCard item={e} marker={query}/>
              </Box>
            </ListItem>
          ))}
        </Stack>
      </List>

      {abstracts.length === 0 && <Typography sx={{textAlign: 'center', marginTop: '50px'}} variant="h6">No results, please try another query</Typography>}

      {renderSearch()}

    </Container>
  );
}

export default AbstractList;
