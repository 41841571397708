import React, { useState, useEffect } from 'react';

import { Container, Typography } from '@mui/material';

function InfoPage(props: {info: any}) {
  return (
    <Container maxWidth="sm" sx={{overflow: 'hidden'}}>
      <Typography>
        <div className="bootstrap-scope" dangerouslySetInnerHTML={{__html: props.info.description}}></div>
      </Typography>
    </Container>
  );
}

export default InfoPage;
