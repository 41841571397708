import React, { ReactElement, useState } from 'react';

import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NumbersIcon from '@mui/icons-material/Numbers';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Card, CardContent, CardMedia, CardActions, Typography, Button, Link as MuiLink, Divider, Stack, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Marker } from "react-mark.js";

export type Item = {
  pk: number,
  title?: string,

  persons?: Array<string>,
  organizations?: Array<string>,
  description?: string,
  location?: string,
  topic?: string,
  summary?: string,
  poster?: string,
  no_favorite?: boolean,

  times?: {
    start?: string,
    end?: string,
    all_day?: boolean,
  }

  image?: string,
  button?: {
    to: string,
    text: string,
  },

  children?: Array<Item>
}

export type Props = {
  item: Item,
  marker?: string,
  expand?: boolean,
  setExpand?: () => void,
  favorites?: Array<number>,
  setFavorites?: (state: Array<number>) => void,
  removeButton?: boolean,
}

function renderPersons(persons: Array<string>, marker?: string) {
  return <Stack direction="row" alignItems="top" gap={1}>
    <PersonIcon fontSize="small" />
    <Stack direction="column">
      <ConditionalMarker marker={marker}>
        {persons.map(p => <div key={`item-${p}`}>{p}</div>)}
      </ConditionalMarker>
    </Stack>
  </Stack>
}

function renderLocation(location: string, marker?: string) {
  return <Stack direction="row" alignItems="top" gap={1}>
    <RoomIcon fontSize="small" />
    <ConditionalMarker marker={marker}>
      {location}
    </ConditionalMarker>
  </Stack>
}

function renderTopic(topic: string, marker?: string) {
  return <Stack direction="row" alignItems="top" gap={1}>
    <DescriptionIcon fontSize="small" />
    <ConditionalMarker marker={marker}>
      {topic}
    </ConditionalMarker>
  </Stack>
}

function renderPoster(poster: string) {
  return <Stack direction="row" alignItems="top" gap={1}>
    <NumbersIcon fontSize="small" />
    {poster}
  </Stack>
}

function renderOrganizations(organizations: Array<string>, marker?: string) {
  return <Stack direction="row" alignItems="top" gap={1}>
    <CorporateFareIcon fontSize="small" />
    <Stack direction="column">
      <ConditionalMarker marker={marker}>
        {organizations.map(p => <span key={`item-${p}`}>{p}<br /></span>)}
      </ConditionalMarker>
    </Stack>
  </Stack>
}

function renderFavorite(pk: number, favorites: Array<number>, setFavorites: (state: Array<number>) => void, removeButton: boolean) {
  if (removeButton) return <IconButton onClickCapture={() => setFavorites(favorites.filter(i => i !== pk))}>
    <RemoveCircleIcon fontSize="large" sx={{alignSelf: "center", color: "#f44336"}} />
  </IconButton>

  return <>
    <IconButton onClickCapture={() => favorites.includes(pk) ?
      setFavorites(favorites.filter(i => i !== pk)) :
      setFavorites([...favorites, pk])
    }>
    {favorites.includes(pk) ? 
      <StarIcon fontSize="large" sx={{alignSelf: "center", color: "#ffa000"}} /> :
      <StarOutlineIcon fontSize="large" sx={{alignSelf: "center", color: "#aeaeae"}} 
    />}
    </IconButton>
  </>
}

function ConditionalMarker(props: {children: any, marker?: string}): any  {
  return props.marker ? <Marker options={{separateWordSearch: false}} mark={props.marker}>{props.children}</Marker> : props.children;
}


function ListCard(props: Props) {
  const item = props.item;
  const marker = props.marker;

  return (
    <Card elevation={4}>
      <CardContent>
        <Stack direction='row'>
          <Stack direction='column' flexGrow="1">
            {item.title && <Typography variant="h5">
              <ConditionalMarker marker={marker}>{item.title}</ConditionalMarker>
            </Typography>}
            <Typography variant="body1" color='primary'>
              {item.topic && renderTopic(item.topic!, marker)}
              {item.location && renderLocation(item.location!, marker)}
              {item.persons && item.persons.length > 0 && renderPersons(item.persons, marker)}
              {item.organizations && item.organizations.length > 0 && renderOrganizations(item.organizations!, marker)}
            </Typography>
          </Stack>

          {item.times && <Typography variant="subtitle1" >
            <Stack direction='column' height="100%" gap={0} sx={{paddingLeft: '20px'}}>
              {item.times.start && <b>{item.times.start}</b>}
              {item.times.end && <b>{item.times.end}</b>}
              {item.times.all_day && <b>All day</b> }
            </Stack>
          </Typography>}
        </Stack>
      </CardContent>

      {item.description && <>
        <Accordion disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Show Summary</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{padding: '0px'}}>
            <Divider />
            <Typography variant="subtitle1" sx={{padding: '10px'}}>
              <div className="summernote-field bootstrap-scope" dangerouslySetInnerHTML={{__html: item.description}}></div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>}

      {item.summary && <>
        <Divider />
        <CardContent>
          <Typography variant="subtitle1">
            {item.summary}
          </Typography>
        </CardContent>
      </>}

      {item.image && <>
        <Divider />
          <CardMedia 
            component="img"
            image={item.image}
          />
        <Divider />
      </>}
      
      {item.children && item.children.length > 0 && <>
        <Accordion expanded={props.expand} onChange={props.setExpand} disableGutters={true} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Show Events</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{padding: '0px'}}>
            {item.children.map(c => <div key={`item-${c.pk}`}>
              <Divider />
              <Stack direction='row' sx={{padding:'16px'}}>
                <Stack direction='column' flexGrow="1">
                  {c.title && c.button && <Typography variant="subtitle1">
                    <MuiLink to={c.button.to} component={Link} fontSize='18px'>
                      <ConditionalMarker marker={marker}>{c.title}</ConditionalMarker>
                    </MuiLink>
                  </Typography>}
                  <Typography variant="body1" color='primary'>
                    {c.persons && c.persons.length > 0 && renderPersons(c.persons, marker)}
                    {c.organizations && c.organizations.length > 0 && renderOrganizations(c.organizations, marker)}
                    {c.location && renderLocation(c.location, marker)}
                    {c.topic && renderTopic(c.topic, marker)}
                    {c.poster && renderPoster(c.poster)}
                  </Typography>
                </Stack>
                <Stack direction='column' width={"40px"} sx={{paddingLeft: "20px"}}>
                  {c.times?.start && <Typography variant="subtitle1" sx={{alignSelf: "right", textAlign: "right"}}>
                    {c.times.start}
                  </Typography>}
                  {props.favorites && props.setFavorites && c.no_favorite !== true && <div style={{alignSelf: "center"}}>{renderFavorite(c.pk, props.favorites, props.setFavorites, props.removeButton ?? false)}</div>}
                </Stack>
              </Stack>
            </div>)}
          </AccordionDetails>
        </Accordion>
      </>}

      {item.button && <CardActions>
        <Button fullWidth variant='outlined' to={item.button.to} component={Link}>{item.button.text}</Button>
      </CardActions>}
    </Card>
  );
}

export default ListCard;

export {renderLocation, renderPoster}