import { useCallback, useEffect, useRef, useState } from "react";
import { Fab, Paper, Stack, TextField, useMediaQuery } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Item } from './components/ListCard';

export function usePrevious(value: any): any {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export function useScroll() {
    let [scroll, setScroll] = useState<number>(0);
    useEffect(() => {
        function handler () {
            setScroll(window.scrollY);
        }
        window.addEventListener("scroll", handler, true);
        return () => { window.removeEventListener("scroll", handler) }
    }, [scroll, setScroll]);
    return scroll;
}

export function useScrollRestore(active: boolean) {
    const prevActive = usePrevious(active);
    const currentScroll = useScroll();
    const [scroll, setScroll] = useState<number>(0);
    useEffect(() => {
      if(!prevActive && active) window.scrollTo({top: scroll});
      if(active) setScroll(currentScroll);
    }, [prevActive, active, currentScroll]);
}

// const renderSearch = useCallback(() => (      
//   <Stack direction='row' style={{display: 'flex', alignItems: 'end', position: 'fixed', right: '20px', left: '20px', bottom: '80px', zIndex: 100}} sx={{alignContent: 'flex-end'}}>
//   <Paper elevation={4}  sx={{flexGrow: 1, background: 'white', margin: '0 20px', display: searching ? 'block' : 'none'}}>
//     <TextField inputRef={queryRef} sx={{width: '100%'}} value={query} onChange={(e) => setQuery(e.target.value)} />
//   </Paper>
//   <Fab color={searching ? "error" : "primary"} onClick={() => setSearching(!searching)} sx={{marginLeft: 'auto'}}>
//     {searching ? <CloseIcon /> : <SearchIcon />}
//   </Fab>
// </Stack>), [queryRef, query, setQuery, searching, setSearching]);

const QUERY_LENGTH = 3;

export function useSearch(): [string, () => JSX.Element] {
    const queryRef = useRef<HTMLInputElement>(null);
    const [query, setQuery] = useState<string>("");
    const [searching, setSearching] = useState(false);
    
    useEffect(() => {
      if (searching) queryRef.current?.focus();
      if (!searching) setQuery("");
    }, [searching]);

    const mobile = useMediaQuery('(max-width:520px)');

    const renderSearch = useCallback(() => (<>
      <div style={{margin: '80px'}}></div>
      <Paper elevation={4}  sx={{background: 'white', display: searching ? 'block' : 'none', position: 'fixed', bottom: '80px', right: '100px', left: mobile ? '20px' : '100px'}}>
        <TextField inputRef={queryRef} sx={{width: '100%'}} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="At least 3 characters" error={query.length < QUERY_LENGTH} />
      </Paper>

      <Fab color={searching ? "error" : "primary"} onClick={() => setSearching(!searching)} sx={{position: 'fixed', right: '20px', bottom: '80px'}}>
        {searching ? <CloseIcon /> : <SearchIcon />}
      </Fab>

    </>), [queryRef, query, setQuery, searching, setSearching]);
    
    return [query.length < QUERY_LENGTH ? "" : query, renderSearch];
}

function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function matchItem(query: string, item: Item): boolean {
  if (query === "") return true;

  const q = query.toLowerCase();
  if (item.title?.toLowerCase().includes(q)) return true;
  if (item.location?.toLowerCase().includes(q)) return true;
  if (item.topic?.toLowerCase().includes(q)) return true;
  if (item.organizations?.some(p => p.toLowerCase().includes(q))) return true;
  if (item.persons?.some(p => p.toLowerCase().includes(q))) return true;
  return false;
}

const LOCALSTORAGE_KEY = "favorites";

export function useLocalStorage(): [Array<number>, (state: Array<number>) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Array<number>>(() => {
    if (typeof window === "undefined") {
      return [];
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(LOCALSTORAGE_KEY);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : [];
    } catch (error) {
      // If error also return initialValue
      console.error("error getting localstorage", {error});
      return [];
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: Array<number>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(valueToStore));
      }
      // Save state
      setStoredValue(valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      alert("Sorry, this device does not allow saving favourites locally");
      console.error("error setting localstorage", {error});
    }
  };
  return [storedValue, setValue];
}