import {  Container, Card, CardContent, CardMedia, Typography, List, Stack, Box, ListItem, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

function StaffList(props: {staff: Array<{title: string, email: string, portrait?: string, description?: string}>}) {
  return (
    <Container maxWidth="sm">
      <List>
      <Stack gap={1}>
      {props.staff.map(person => 
        <>
          <ListItem key={`item-${person.title}`}>
              <Box width="100%">
                <Card elevation={4}>
                  {person.portrait && <CardMedia
                    component="img"
                    image={person.portrait}
                    alt={person.title}
                  />}
                  <CardContent>
                    <Stack direction='row' flexGrow="1">
                      <Stack direction='column' flexGrow="1">
                        <Typography variant="h5">{person.title}</Typography>
                        <Typography sx={{fontSize: '1em', fontStyle: 'italic'}}>{person.email}</Typography>
                      </Stack>
                      <div style={{alignSelf: "right"}}>
                        <IconButton color='primary' href={`mailto:${person.email}`}><EmailIcon /></IconButton>
                      </div>
                    </Stack>
                    {person.description && <Typography variant="body1" color='primary'>
                      {person.description}
                    </Typography>}
                  </CardContent>
                </Card>
              </Box>
            </ListItem>
        </>
      )}
      </Stack>
      </List>
    </Container>
  );
}

export default StaffList;
