import React, { useEffect, useState } from 'react';

import { Stack, Container, CircularProgress, Box, Card, CardContent, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ListCard, { renderLocation, renderPoster } from '../components/ListCard';

import { Item } from '../components/ListCard';
import { useParams } from 'react-router-dom';

type Pres = {
  'presentations': Array<{pk: number, location?: string, poster?: string, times?: string}>
}

function AbstractDetail() {
  let { id } = useParams();
  const [data, setData] = useState<Item & Pres | null>(null);
  useEffect(() => {
    if (id === undefined || id === null) return
    fetch(`/api/v1/calendar/abstracts/${id}`)
    .then(res => res.json())
    .then(res => setData(res));
  }, [id]);

  if(data === null) return (<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{margin: '50px auto'}}>
      <CircularProgress sx={{display: 'block'}} />
    </div>
  </Box>);

  return (
    <Container maxWidth="sm">
      <ListCard item={data}/>

      {data.presentations.length > 0 && <Typography sx={{marginTop: '16px'}} variant="h5">Related presentations:</Typography>}

      {data.presentations.map(pres =>
        <Card key={pres.pk} elevation={4} sx={{marginTop: '16px'}}>
          <CardContent>
            <Typography variant="body1" color='primary'>
              {pres.times && 
                <Stack direction="row" alignItems="top" gap={1}>
                  <ScheduleIcon fontSize="small" />
                  {pres.times!}
                </Stack>
              }
              {pres.location && renderLocation(pres.location!)}
              {pres.poster && renderPoster(pres.poster!)}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default AbstractDetail;
