import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Navigate, Routes, useLocation } from "react-router-dom";
import './styles/index.css';

import { Stack, Grid, Box, Slide, CircularProgress } from '@mui/material';

import NavBar from './components/NavBar';
import { Item } from './components/ListCard';

import EventList from './routes/EventList';
import AbstractList from './routes/AbstractList';
import AbstractDetail from './routes/AbstractDetail';
import InfoPage from './routes/InfoPage';
import UserFavorites from './routes/UserFavorites';
import StaffList from './routes/StaffList';
import { useLocalStorage } from './utils';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function KeepAliveRoutes(props: {days: Array<{name: string, events: Array<Item>, index: number}>, abstracts: Array<Item>, favorites: Array<number>, setFavorites: (state: Array<number>) => void}) {
  const location = useLocation();

  return <>
    {props.days.map(d => (
      <div key={`item=${d.index}`} style={{display: (location.pathname === `/events/${d.index}` ? 'block': 'none')}}>
        <EventList favorites={props.favorites} setFavorites={props.setFavorites} active={location.pathname === `/events/${d.index}`} day={d} />
      </div>
    ))}
    <div style={{display: (location.pathname === '/abstracts' ? 'block': 'none')}}>
      <AbstractList active={location.pathname === '/abstracts'} abstracts={props.abstracts} />
    </div>
  </>
}

function App() {
  const [days, setDays] = useState<Array<{name: string, events: Array<Item>}>>([]);
  useEffect(() => {
    fetch("/api/v1/calendar/events")
    .then(res => res.json())
    .then(res => setDays(res));
  }, []);

  const [info, setInfo] = useState<any>(null);
  useEffect(() => {
    fetch("/api/v1/calendar/info")
    .then(res => res.json())
    .then(res => setInfo(res));
  }, []);

  const [staff, setStaff] = useState<any>(null);
  useEffect(() => {
    fetch("/api/v1/calendar/staff")
    .then(res => res.json())
    .then(res => setStaff(res));
  }, []);

  const [abstracts, setAbstracts] = useState<Array<Item>>([]);
  useEffect(() => {
    fetch("/api/v1/calendar/abstracts")
    .then(res => res.json())
    .then(res => setAbstracts(res));
  }, []);

  const [favorites, setFavorites] = useLocalStorage();


  if(info === null || days.length === 0 || abstracts.length === 0) return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{margin: '50px auto'}}>
        <CircularProgress sx={{display: 'block'}} />
      </div>
    </Box>
  );

  return (<>
    <Box sx={{padding: "10px 0"}}>
      <KeepAliveRoutes days={days.map((d, i) => ({...d, index: i}))} favorites={favorites} setFavorites={setFavorites} abstracts={abstracts}/>
      <Routes>
        <Route path="/" element={<InfoPage info={info} />} />
        {/* These render nothing, as they are kept-alive using KeepAliveRoutes */}
        <Route path="/events/:index" element={<></>} />
        <Route path="/abstracts" element={<></>} />

        <Route path="/abstracts/:id" element={<AbstractDetail />} />
        <Route path="/favorites" element={<UserFavorites favorites={favorites} setFavorites={setFavorites} days={days} />} />
        <Route path="/staff" element={<StaffList staff={staff} />} />

      </Routes>
    </Box>
    <NavBar days={days.map((d, i) => ({name: d.name, index:i}))}/>
  </>);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
