import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Stack, Container, Divider, Chip, List, ListSubheader, ListItem, Box, CircularProgress, TabScrollButton, Fab, StyledComponentProps, TextField, Paper, Typography } from '@mui/material';

import ListCard from '../components/ListCard';
import { Item } from '../components/ListCard';
import { matchItem, useScrollRestore, useSearch } from '../utils';

function EventList(props: {active: boolean, day: {name: string, events: Array<Item>}, favorites: Array<number>, setFavorites: (state: Array<number>) => void,}) {
  useScrollRestore(props.active);

  const [query, renderSearch] = useSearch();
  const events = (query === "" ? props.day.events : props.day.events.reduce((acc, item) => {
    if (item.children?.some(c => matchItem(query, c))) {
      return [...acc, { ...item, children: item.children.filter(c => matchItem(query, c))}];
    }
    if (matchItem(query, item)) {
      return [...acc, item];
    }
    return acc;
  }, [] as Array<Item>));

  const [expand, setExpand] = useState<number | null>(null);
  
  return (
    <Container maxWidth="sm" style={{paddingBottom: '80px'}}>
      <List>
        <Stack gap={1}>

          <ListSubheader>
            <Divider sx={{margin: '20px 0'}}>
              <Chip label={props.day.name} color='primary' sx={{fontSize: '20px'}} />
            </Divider>
          </ListSubheader>

          {events.map(e => (
            <ListItem key={`item-${e.pk}`}>
              <Box width="100%">
                <ListCard item={e} marker={query} expand={expand === e.pk || query !== ""} favorites={props.favorites} setFavorites={props.setFavorites} setExpand={() => expand === e.pk ? setExpand(null) : setExpand(e.pk)}/>
              </Box>
            </ListItem>
          ))}

        </Stack>
      </List>

      {events.length === 0 && <Typography sx={{textAlign: 'center', marginTop: '50px'}} variant="h6">No results, please try another query</Typography>}

      {renderSearch()}

    </Container>
  );
}

export default EventList;
