import React from 'react';

import EventIcon from '@mui/icons-material/Event';
import HelpIcon from '@mui/icons-material/Help';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MenuIcon from '@mui/icons-material/Menu';
import NewsPaperIcon from '@mui/icons-material/Newspaper';
import StarIcon from '@mui/icons-material/Star';

import {  Divider, BottomNavigation, BottomNavigationAction, Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function NavBar(props: {days: Array<{index: number, name: string}>}) {
  const location = useLocation();
  const currentTab = (location.pathname.startsWith("/abstracts") ? "/abstracts" : location.pathname);

  const [value, setValue] = React.useState(currentTab);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [drawer, setDrawer] = React.useState(false);

  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: 1.0, zIndex:100 }}>
      <Drawer open={drawer} onClose={() => setDrawer(false)} anchor='bottom'>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/abstracts" onClick={() => setDrawer(false)} selected={currentTab === '/abstracts'} >
              <ListItemIcon><NewsPaperIcon /></ListItemIcon>
              <ListItemText>Abstracts</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/favorites" onClick={() => setDrawer(false)} selected={currentTab === '/favorites'} >
              <ListItemIcon><StarIcon /></ListItemIcon>
              <ListItemText>Favorites</ListItemText>
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/staff" onClick={() => setDrawer(false)} selected={currentTab === '/staff'}>
              <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
              <ListItemText>Staff</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Divider />
      <BottomNavigation
        value={currentTab}
        onChange={handleChange}
        showLabels
      >

        <BottomNavigationAction label="Info" icon={<HelpIcon />} component={Link} value="/" to="/" />
        {props.days.map(({index, name}) => <BottomNavigationAction key={`item-${index}`} label={name.slice(5)} icon={<EventIcon />} component={Link} value={`/events/${index}`} to={`/events/${index}`} />)}
        <BottomNavigationAction label="More" icon={<MenuIcon />} onClick={() => setDrawer(true)} />
      </BottomNavigation>
    </Box>
  );
}

export default NavBar;
